import Action from '../Action'
import { sum, get } from 'lodash'
import colors from 'shared/colors'
import { applyOffset } from 'shared/util/time/offset.js'
import moment from 'moment-timezone'

class Throttle extends Action {
  static get title () {
    return 'Throttle'
  }

  static get class () {
    return 'Throttle'
  }

  static get color () {
    return colors.goldYellow
  }

  // static leftNumber (block) {
  //   if (block && block.stats && block.stats.tasks) {
  //     return block.stats.tasks || 0
  //   }
  //   return 0
  // }

  static getDescription ({ block = {} } = {}) {
    if (block && block.props && block.props.interval) {
      const interval = block.props.interval
      return [
        [interval.years, 'Y'],
        [interval.months, 'M'],
        [interval.weeks, 'W'],
        [interval.days, 'D'],
        [interval.hours, 'h'],
        [interval.minutes, 'm']
      ].filter(([amt]) => !!amt && amt > 0)
        .map(([amt, suffix]) => `${amt}${suffix}`)
        .join(',')
    }
    return ''
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []
    const totalInterval = Object.values(get(block, 'props.interval') || {}).map(v => (v * 1) || 0)
    if (!sum(totalInterval)) {
      errors.push({
        message: 'Throttle interval is empty.'
      })
    } else {
      const now = moment()
      const nowWithOffset = applyOffset(block?.props?.interval, now)
      if (nowWithOffset.unix() - now.unix() > 7 * 24 * 3600) {
        errors.push({
          message: 'Timeframe cannot be more than 7 days.'
        })
      }
      // If the interval is greater than 4 weeks, throw an error
      return super.getErrors(environment).concat(errors)
    }
    // If totalInterval is greater than 18 months throw an error
    return super.getErrors(environment).concat(errors)
  }
}

export default Throttle
