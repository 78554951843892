import Action from '../Action'
import colors from 'shared/colors'

class Eject extends Action {
  static get title () {
    return 'Eject If Delayed'
  }

  static get class () {
    return 'Eject'
  }

  static get noConfiguration () {
    return true
  }

  static get color () {
    return colors.goldYellow
  }
}

export default Eject
