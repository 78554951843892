// Returns an object of all schemas added based on the Court Reserve addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact.last_membership': {
      type: 'text',
      name: 'Last Membership'
    },
    'contact.last_membership_acquired': {
      type: 'timestamp',
      name: 'Last Membership Acquired'
    },
    'contact.last_membership_expiration': {
      type: 'timestamp',
      name: 'Last Membership Expiration'
    },
    'contact.last_visit_date': {
      type: 'date',
      name: 'Last Visit Date'
    },
    'contact.last_visit_type': {
      type: 'text',
      name: 'Last Visit Type'
    },
    'contact.member': {
      type: 'checkbox',
      name: 'Member'
    },
    'contact.membership_type': {
      type: 'text',
      name: 'Membership Type'
    },
    'contact.optin_request_sms': {
      type: 'checkbox',
      name: 'Optin Request SMS'
    },
    'contact.player_id': {
      type: 'text',
      name: 'Playbypoint Player Id (deprecated, use _external_id)'
    },
    'contact._external_id': {
      type: 'text',
      name: 'Playbypoint Player ID'
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
