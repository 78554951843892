export default [
  {
    name: 'Text',
    value: 'text'
  },
  {
    name: 'Number',
    value: 'number'
  },
  {
    name: 'Punchcard',
    value: 'punchcard'
  },
  {
    name: 'Date',
    value: 'date'
  },
  {
    name: 'Time-stamp',
    value: 'timestamp'
  },
  {
    name: 'Checkbox (yes/no)',
    value: 'checkbox'
  },
  {
    name: 'Image',
    value: 'image'
  },
  {
    name: 'Password',
    value: 'password'
  },
  {
    name: 'Time',
    value: 'time'
  },
  {
    name: 'Object',
    value: 'object'
  }
]
