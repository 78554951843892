import { pick, toNumber } from 'lodash'

export default {
  props: {
    value: {},
    schema: {
      type: Object,
      default: () => ({ type: 'text' })
    },
    label: {
      type: String,
      default: undefined
    },
    filled: {
      type: Boolean,
      default: false
    },
    filter: {
      // shhhh you [object Object]
      required: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [String, Boolean],
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: undefined
    },
    suffix: {
      type: String,
      default: undefined
    },
    autofocus: {
      type: Boolean,
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    backgroundColor: {
      type: String,
      default: undefined
    },
    fieldProps: { // Any other props specific to the resulting primitive field that should be passed through
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    props () {
      return {
        value: this.value,
        schema: this.schema,
        label: this.label,
        placeholder: this.placeholder,
        prefix: this.prefix || this.fieldProps?.addon_prefix,
        suffix: this.suffix || this.fieldProps?.addon_suffix,
        disabled: this.disabled,
        dense: this.dense,
        hideDetails: this.hideDetails,
        filled: this.filled === undefined ? true : this.filled,
        outlined: this.outlined === undefined ? true : this.outlined,
        autofocus: this.autofocus,
        color: this.color,
        backgroundColor: this.backgroundColor,
        fieldProps: this.fieldProps,
        rules: this.rules
      }
    },
    vuetifyComponentProps () {
      const result = {
        validateOnBlur: true,
        ...pick(this.props, [
          'value',
          'label',
          'placeholder',
          'prefix',
          'suffix',
          'disabled',
          'dense',
          'hideDetails',
          'filled',
          'outlined',
          'autofocus',
          'color',
          'backgroundColor',
          'rules'
        ])
      }
      return result
    },
    rules () {
      const rules = []
      if (this.fieldProps?.required) {
        if (this.schema?.multiple) {
          rules.push(((this.value || []).length > 0 && !!this.value[0]) || 'At least one item should be selected')
        } else if ((this.fieldOptions || []).length) {
          rules.push(v => !!v || 'Please select an option.')
        } else if (['checkbox', 'boolean'].includes(this.schema?.type)) {
          rules.push(v => [true, false].includes(v) || 'This field is required.')
        } else if (['date'].includes(this.schema?.type)) {
          // When it's dropdown_mmdd, only validate whether month and day have values
          if ((this.fieldProps?.input_type || this.schema?.input_type) === 'dropdown_mmdd') {
            rules.push(v => {
              if (typeof v === 'object') {
                return (+v?.m > 0 && +v?.d > 0) || 'This field is required.'
              } else {
                const parts = ((v || '') + '').split('-')
                return (parts[1] * 1 > 0 && parts[2] * 1 > 0) || 'This field is required.'
              }
            })
          } else {
            // Year, month, and day all need values
            rules.push(v => {
              if (typeof v === 'object') {
                return (+v?.y > 0 && +v?.m > 0 && +v?.d > 0) || 'This field is required.'
              } else {
                const parts = ((v || '') + '').split('-')
                return (parts[0] * 1 > 0 && parts[1] * 1 > 0 && parts[2] * 1 > 0) || 'This field is required.'
              }
            })
          }
        } else if (['timestamp'].includes(this.schema?.type)) {
          rules.push(v => {
            // It's relative timestamp if the value includes smart text; otherwise check for complete date
            if (!((v || '') + '').includes('{{')) {
              if (typeof v === 'object') {
                return (+v?.y > 0 && +v?.m > 0 && +v?.d > 0) || 'This field is required.'
              } else {
                // v is in this format: 2020-01-30T08:05:00 or 2020-01-30T08:05:00.000Z
                const parts = ((v || '') + '').split('-')
                return (parts[0] * 1 > 0 && parts[1] * 1 > 0 && (parts[2] || '').substring(2, 0) * 1 > 0) || 'This field is required.'
              }
            } else {
              rules.push(v => !!v || 'This field is required.')
            }
          })
        } else {
          rules.push(v => !!v || 'This field is required.')
        }
      }
      return rules
    },
    fieldOptions () {
      if (this.fieldProps?.options?.length) {
        let options = this.fieldProps?.options || []
        // cast values to number if numeric schema type
        if (['number', 'punchcard'].includes(this.schema?.type)) {
          options = options.map(option => ({
            ...option,
            value: toNumber(option?.value)
          }))
        }
        return options.filter(fieldOption => (this.schema?.options || []).find(schemaOption => schemaOption.value === fieldOption.value))
      } else {
        return this.schema?.options || []
      }
    }
  },
  methods: {
    handleInput (value) {
      // Emit op here if needed
      let result = value
      if (['', '[]', '{}', 'null'].includes(JSON.stringify(result))) {
        result = null
      }
      this.$emit('input', result)
    },
    focus () {
      // Some Vuetify components that extend v-input don't implement a "focus"
      // method, even though they contain focusable input elements, and MUST
      // be focusable so we can scroll to them when they have validation errors.
      // The fact that they must be handled separately is why this ref is named:
      // `focusTargetButItsWorseForNoReason`
      const {
        focusTargetButItsWorseForNoReason,
        focusTarget
      } = this.$refs
      const focusMethod = focusTargetButItsWorseForNoReason
        ? () => {
            const nativeInputElement = focusTargetButItsWorseForNoReason.$el.querySelector('input')
            if (nativeInputElement) {
              nativeInputElement.focus()
            }
          }
        : focusTarget?.focus
      if (typeof focusMethod === 'function') {
        focusMethod()
      }
    }
  }
}
