<template>
  <v-card
    class="block-editor cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>AI Generator</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="requestClose"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      class="pa-4"
      ref="scroller"
    >
      <p
        v-if="insertMode"
      >
        Edit this AI prompt as needed and then click the Generate button below to review the results.
      </p>
      <p
        v-else
      >
        This will be used as the default AI prompt to generate content when the user chooses Insert - AI Generated.
        To test the prompt, use the Generate button below.
      </p>
      <v-switch
        v-if="$restrict({ 'user.level': { $gte: 9 } })"
        v-model="advancedMode"
        :loading="loading"
        label="Advanced Editing (User Level >= 9)"
        color="success"
        hide-details
        class="pb-3 pt-0 pr-1"
      />
      <v-textarea
        v-if="advancedMode && schema"
        disabled
        outlined
        dense
        hide-details
        rows="3"
        class="mt-1 mb-5"
        label="Response Schema"
        :value="schemaDisplay"
      />
      <v-textarea
        v-if="advancedMode && autoDeveloperPrompt"
        disabled
        outlined
        dense
        hide-details
        rows="3"
        class="mt-3 mb-5"
        label="Auto Developer Prompt (based on schema and options below)"
        :value="autoDeveloperPrompt"
      />
      <v-textarea
        v-if="advancedMode"
        outlined
        dense
        hide-details
        rows="3"
        class="mt-3 mb-5"
        label="Custom Developer Prompt (optional)"
        :value="recordChanged.developer_message"
        @input="$handleChange('record', 'developer_message', $event)"
      />
      <v-textarea
        outlined
        dense
        rows="6"
        class="mt-3 mb-2"
        :label="`${advancedMode ? 'User ' : 'AI '}Prompt`"
        placeholder="Explain what you would like AI to write. Include any specific details you'd like to include or exclude from the message content."
        persistent-placeholder
        hint="Example: Write a compelling email message to encourage customers to come in and check out our holiday espresso / coffee drinks. Be sure to mention our buy one get one coupon code which is BY2025."
        persistent-hint
        :value="recordChanged.user_message"
        @input="$handleChange('record', 'user_message', $event)"
      />
      <v-checkbox
        :label="`Include these business details for a more customized response: ${businessInfo.summary}`"
        hide-details
        dense
        :input-value="!recordChanged.exclude_business_info"
        @click="$handleChange('record', 'exclude_business_info', !recordChanged.exclude_business_info)"
      />

      <template
        v-if="recordChanged.type === 'sms'"
      >
        <v-checkbox
          label="Allow emojis and unicode characters (increases message pricing)."
          hide-details
          dense
          :input-value="recordChanged.allow_unicode"
          @click="$handleChange('record', 'allow_unicode', !recordChanged.allow_unicode)"
        />
        <v-checkbox
          label="Allow response to exceed 160 characters."
          hide-details
          dense
          :input-value="recordChanged.allow_long"
          @click="$handleChange('record', 'allow_long', !recordChanged.allow_long)"
        />

      </template>

      <div class="d-flex justify-center text-center">
        <v-btn
          class="mt-3"
          color="primary"
          :disabled="loading || loadingGenerate || !recordChanged.user_message"
          @click="generate"
        >
          <v-icon small class="mr-2">fa-solid fa-wand-magic-sparkles</v-icon>
          <span>{{ aiResponse ? 'Re-Generate' : 'Generate' }}</span>
          <v-progress-circular
            v-if="loadingGenerate"
            class="ml-2"
            size="20"
            indeterminate
          />
        </v-btn>
      </div>

      <template
        v-if="!emptyResponse && !loadingGenerate"
      >
        <template
          v-if="insertMode"
        >
          <v-textarea
            v-if="aiTextResponse"
            outlined
            dense
            hide-details
            rows="7"
            class="my-3"
            label="AI Content"
            v-model="aiTextResponse"
          />

          <div
            v-for="response of aiStructuredResponses"
            :key="response.key"
            class="d-flex flex-grow-1"
          >
            <v-textarea
              outlined
              dense
              hide-details
              :rows="response.key === 'email_subject' ? 1 : 10"
              class="mt-3"
              :label="response.label"
              :value="response.val"
              @input="handleStructuredResponseChange(response.key, $event)"
            />
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on }">
                <div class="ml-3 mt-4">
                  <v-icon
                    small
                    v-on="on"
                    @click="handleStructuredResponseChange(response.key, null)"
                  >fa fa-times</v-icon>
                </div>
              </template>
              <span>Remove {{ response.label }}</span>
            </v-tooltip>
          </div>
        </template>
        <template
          v-else
        >
          <v-card
            v-if="aiTextResponse"
            class="my-3"
          >
            <v-card-text>
              <span
                v-html="aiTextResponseHtml"
              />
            </v-card-text>
          </v-card>

          <div
            v-for="response of aiStructuredResponses"
            :key="response.key"
            class="my-3"
          >
            <h5 class="pb-1">{{ response.label }}</h5>
            <v-card>
              <v-card-text>
                <span
                  v-html="response.valFormatted"
                />
              </v-card-text>
            </v-card>
          </div>
        </template>
      </template>
    </v-container>
    <v-footer
      class="d-flex py-4"
    >
    <v-spacer />
    <v-btn
      class="ml-2"
      :disabled="loading"
      @click="requestClose"
      outlined
      color="warning"
    >{{ recordChanges.length || (insertMode && !emptyResponse) ? 'Cancel' : 'Close' }}</v-btn>
    <v-btn
      class="ml-2"
      color="primary"
      :disabled="saveDisabled"
      @click="save"
    >
      <span>{{ insertMode ? 'Use' : 'Save &amp; Close' }}</span>
      <v-progress-circular
        v-if="loading"
        class="ml-2"
        size="20"
        indeterminate
      />
    </v-btn>
    </v-footer>
  </v-card>
</template>
<script>
import { startCase } from 'lodash'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import { mapGetters } from 'vuex'

// response_format - Structured outputs / response
// schema name is mapped to ai_prompt.type
// https://platform.openai.com/docs/guides/structured-outputs
const responseSchemas = [
  {
    name: 'email',
    schema: {
      type: 'object',
      properties: {
        email_body: {
          type: 'string',
          description: 'The email body.'
        },
        email_subject: {
          type: 'string',
          description: 'The email subject.'
        }
      },
      required: [
        'email_body',
        'email_subject'
      ],
      additionalProperties: false
    },
    strict: true
  }
]

export default {
  oWindow: {
    width: 825,
    height: 850
  },
  mixins: [
    createChangeTrackerMixin({ path: 'record' })
  ],
  props: {
    record: {
      type: Object
    },
    insertMode: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      loadingGenerate: false,
      aiTextResponse: null,
      aiStructuredResponse: null,
      advancedMode: false
    }
  },
  computed: {
    ...mapGetters(['account']),
    emptyResponse  () {
      return !this.aiTextResponse && !this.aiStructuredResponses.length
    },
    saveDisabled () {
      if (this.insertMode) {
        return this.emptyResponse || this.loadingGenerate
      } else {
        return this.loading || !this.recordChanges.length
      }
    },
    schema () {
      return responseSchemas.find(s => s.name === this.recordChanged.type) || undefined
    },
    autoDeveloperPrompt () {
      let prompt = ''
      if (this.recordChanged.type === 'sms') {
        if (!this.recordChanged.allow_unicode) {
          prompt += ' Do not include emojis. Do not include unicode characters.'
        }
        if (!this.recordChanged.allow_long) {
          prompt += ' Response should not exceed 160 characters.'
        }
      }
      if (this.schema) {
        // even with the provided schema, sometimes the AI response includes the subject in the body and we need to provide additional guidance
        prompt += ' Use the provided JSON schema as the response format. Do not include the email subject in the email body. The email subject should be in the email_subject field.'
      }
      if (!this.recordChanged.exclude_business_info) {
        prompt += ` ${this.businessInfo.details}`
      }
      return prompt.trim()
    },
    aiResponse () {
      return this.aiTextResponse || this.aiStructuredResponse
        ? {
            text: this.aiTextResponse,
            structured: this.aiStructuredResponse
          }
        : undefined
    },
    aiTextResponseHtml () {
      return (this.aiTextResponse || '').replaceAll('\n', '<br />')
    },
    aiStructuredResponses () {
      return Object.entries(this.aiStructuredResponse?.data || {})
        .map(([key, val]) => {
          let sort = 1
          if (key === 'email_subject') {
            sort = 0
          }
          return {
            key,
            label: startCase((key || '').replaceAll('_', ' ')),
            val,
            valFormatted: (val || '').replaceAll('\n', '<br />'),
            sort
          }
        }).filter(r => r.val)
        .sort((a, b) => a.sort - b.sort)
    },
    schemaDisplay () {
      return JSON.stringify(this.schema || {}, null, '  ')
    },
    businessInfo () {
      const category = (this.account.settings?.category || '').toLowerCase() && (this.account.settings?.category || '').toLowerCase() !== 'other'
        ? ` Business type or category: ${startCase(this.account.settings?.category || '')}.`
        : ''
      const city = this.account.settings?.address?.city || ''
      const state = this.account.settings?.address?.state ? ' ' + this.account.settings?.address?.state : ''
      const zip = this.account.settings?.address?.zip ? ' ' + this.account.settings?.address?.zip : ''
      const country = this.account.settings?.address?.country && this.account.settings?.address?.country !== 'US'
        ? ', ' + this.account.settings?.address?.country
        : ', USA'
      const location = city || state || zip
        ? ` Business location: ${city}${state}${zip}${country}.`
        : ''
      const website = this.account.settings?.website
        ? ` Business website: ${this.account.settings?.website}`
        : ''
      return {
        details: `Business name: ${this.account.display_name || this.account.name}.${category}${location}${website}`,
        summary: `${this.account.display_name || this.account.name}${location ? `, ${city}${state}${zip}${country}` : ''}${website ? `, ${this.account.settings?.website}` : ''}`
      }
    }
  },
  methods: {
    handleStructuredResponseChange (key, value) {
      this.aiStructuredResponse.data = {
        ...this.aiStructuredResponse.data,
        [key]: value
      }
    },
    async requestClose () {
      if (this.loading) {
        return
      }
      const discard = `Discard ${this.insertMode ? 'AI Content' : 'Changes'}`
      if (
        (this.recordChanges.length || (this.insertMode && !this.emptyResponse)) &&
        (
          await this.$confirm(
            `Are you sure you want to exit without ${this.insertMode ? 'using this AI content' : 'saving this AI Prompt'}?`,
            {
              title: 'Please Confirm',
              buttons: [
                {
                  name: discard,
                  color: 'warning',
                  outlined: true
                },
                {
                  name: 'Continue Editing',
                  color: 'primary'
                }
              ]
            }
          )
        ) !== discard
      ) {
        return
      }
      this.$emit('close')
    },
    async save () {
      this.$emit('close', {
        aiPrompt: this.recordChanged,
        ...(this.emptyResponse ? {} : { aiResponse: this.aiResponse })
      })
    },
    async generate () {
      if (this.loading || this.loadingGenerate || !this.recordChanged.user_message) {
        return
      }
      const developerMessage = ((this.recordChanged.developer_message || '') + ' ' + this.autoDeveloperPrompt).trim()
      const aiPrompt = {
        messages: [
          ...(developerMessage
            ? [{
                role: 'developer',
                content: developerMessage
              }]
            : []),
          {
            role: 'user',
            content: this.recordChanged.user_message
          }
        ],
        ...(this.schema
          ? {
              response_format: {
                type: 'json_schema',
                json_schema: this.schema
              }
            }
          : {}),
        ...(this.recordChanged.metadata
          ? {
              metadata: {
                ...this.recordChanged.metadata,
                type: this.recordChanged.type
              }
            }
          : {})
      }
      try {
        this.loadingGenerate = true
        const response = await this.$http({
          method: 'POST',
          url: '/v2/ai/chat/completions',
          data: {
            aiPrompt
          }
        })
        if (response?.data?.structuredResponse) {
          this.aiStructuredResponse = response?.data?.structuredResponse
          this.aiTextResponse = null
        } else if (response?.data?.textResponse) {
          this.aiStructuredResponse = null
          this.aiTextResponse = response?.data?.textResponse
        }
        if (this.$refs.scroller) {
          this.$nextTick(() => { // scroll to the bottom to see entire response
            this.$refs.scroller.scrollTop = this.$refs.scroller.scrollHeight
          })
        }
      } finally {
        this.loadingGenerate = false
      }
    }
  }
}
</script>
