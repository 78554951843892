import Vuex from 'vuex'
import * as Models from '../models'
import { get, merge } from 'lodash'
import axios from 'axios'
import { getUserFriendlyName, getAccountFriendlyName } from 'shared/util/friendlyName'
import { getSchemas } from 'shared/schemas'
import { getSchemas as getEventSchemas } from 'shared/schemas/events'
import { oldFieldsToSchemas } from 'shared/schemas/legacy'
import { getProperty } from 'shared/schemas/entities'
import globalPlugin from 'ui/plugins/globalPlugin'
import randomString from 'shared/util/randomString'
import sortCaseInsensitive from 'shared/util/sortCaseInsensitive'

import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'

// This exports a function to create the store.  It's necessary to pass in Vue rather than
// import it due to how nativescript-vue creates a specialized version of Vue.
// It's important that this file is only imported a single time for each store that is
// created (typically done in main.js).  It should then be shared from there rather than
// imported elsewhere.

const createAxiosInstance = (axiosConfig, axiosRequestInterceptor) => {
  const axiosInstance = axios.create(axiosConfig)
  let store
  axiosInstance.attachStore = (_store) => {
    store = _store
    store.$http = axiosInstance
  }
  axiosInstance.interceptors.request.use((config) => {
    const skipHeaders = (get(config, 'skipHeaders') || []).map(h => ((h || '') + '').toLowerCase())

    const deviceIdentifier = window.localStorage.getItem('p-device-identifier')

    config.headers = {
      ...(deviceIdentifier
        ? { 'x-device-identifier2': deviceIdentifier } // Change this to x-device-identifier when old portal/kiosk are done
        : {}
      ),
      ...(config.headers || {}),
      'x-relationships': 'all', // always pull relationships
      'x-client-id': process?.env?.VUE_APP_CLIENT_ID || global?.VUE_APP_CLIENT_ID, // Had to remove get() on these due to get not working with proxy objects, webpack defineplugin, yada yada
      'x-client-version': process?.env?.VUE_APP_CLIENT_VERSION || global?.VUE_APP_CLIENT_VERSION
    }
    if (store.getters?.accountId && !skipHeaders.includes('x-account-id') &&
      [undefined, null].includes(config.headers['x-account-id'])
    ) {
      config.headers['x-account-id'] = store.getters.accountId + ''
    }
    if (store.state.token) {
      config.headers.Authorization = `Bearer ${store.state.token}`
    }
    if (store.state.socketId) {
      config.headers['x-socket-id'] = store.state.socketId
    }
    // turns out a bug in Axios still present Jan 2020 prevents the ability to add layered interceptors,
    // as one might normally add multiple event handlers to the same event, like this:
    // `axiosInstance.interceptors.request.use(firstInterceptor)`
    // then, later, in another context
    // `axiosInstance.interceptors.request.use(anotherInterceptorThatReceivesOutputFromFirst)`
    // ref: https://github.com/axios/axios/issues/1226
    // This should accomplish about the same.
    if (axiosRequestInterceptor) {
      return axiosRequestInterceptor(config)
    } else {
      return config
    }
  })

  axiosInstance.interceptors.response.use((response) => {
    if (response?.data?.relationships) {
      Models.addRecordsToStore(response.data.relationships, { force: true })
    }

    return response
  }, (error) => {
    // Ignore errors resulting from cancelled requests
    if (error.message.includes('request cancelled') || (error.constructor && error.constructor.name === 'Cancel')) {
      throw error // The component triggering this should handle the error
    }
    console.error({ error })
    let message = error.message
    if (error.response) {
      message = get(error.response, 'data.error') || get(error.response, 'data') || message
    }
    if (!(error?.hideErrors || error?.config?.hideErrors)) {
      if ((get(error.response, 'status') || '') + '' === '401') {
        store.commit('setRequireLogin', true)
      } else {
        store.dispatch('setAppError', message)
      }
    }
    throw error
  })

  axiosInstance.CancelToken = axios.CancelToken
  return axiosInstance
}
const createMockAxiosInstance = () => {
  const axiosInstanceMock = function () {
    return Promise.reject(new Error('Page is running in `stateless` mode, network requests are not allowed.'))
  }
  axiosInstanceMock.request = axiosInstanceMock
  axiosInstanceMock.attachStore = () => {}
  return axiosInstanceMock
}

const createStylesObject = (account) => {
  return {
    font_family: getProperty('account', account, 'settings.style.font_family'),
    background_color: getProperty('account', account, 'settings.style.background_color'),
    content_background_color: getProperty('account', account, 'settings.style.content_background_color'),
    color: getProperty('account', account, 'settings.style.color'),
    button_background_color: getProperty('account', account, 'settings.style.button_background_color'),
    button_color: getProperty('account', account, 'settings.style.button_color'),
    link_color: getProperty('account', account, 'settings.style.link_color'),
    input_color: getProperty('account', account, 'settings.style.input_color'),
    input_background_color: getProperty('account', account, 'settings.style.input_background_color'),
    link_no_underline: getProperty('account', account, 'settings.style.link_no_underline')
  }
}

export {
  createStylesObject
}

let deviceIdentifier
const generateDeviceIdentifier = () => {
  deviceIdentifier = window.localStorage.getItem('p-device-identifier')
  deviceIdentifier = randomString(40)
  window.localStorage.setItem('p-device-identifier', deviceIdentifier)
}

export default ({
  Vue,
  axiosConfig = {
    paramsSerializer: {
      indexes: null // no brackets for arrays; instead: storeIds=1&storeIds=2&storeIds=3
    }
  },
  axiosRequestInterceptor = null,
  vuexConfig = {},
  bootstrapModels = {}, // Models to load during initial portal load for this specific store instance
  callbacks = {},
  stateless = false, // used in a preview frame with state passed in via postMessage.
  autoGenerateDeviceIdentifier = true // For example, on Kiosk we don't want to auto-generate one of these to allow a previous one to be selected.
}) => {
  deviceIdentifier = window.localStorage.getItem('p-device-identifier')
  if (!deviceIdentifier && autoGenerateDeviceIdentifier) {
    generateDeviceIdentifier()
  }

  Vue.use(globalPlugin)

  const axiosInstance = stateless
    ? createMockAxiosInstance()
    : createAxiosInstance(axiosConfig, axiosRequestInterceptor)
  Vue.prototype.$http = Vue.$http = axiosInstance

  VuexORM.use(VuexORMAxios, {
    baseURL: (get(axiosConfig, 'baseURL') || '') + '/v2/',
    axios: axiosInstance
  })

  Vue.use(Vuex)

  const database = new VuexORM.Database()

  Models.registerModels(database)

  const getErrorMessage = (e) => get(e, 'response.data.error') || get(e, 'response.data') || get(e, 'message') || e

  let appErrorTimeout = null

  const store = new Vuex.Store(merge({
    plugins: [VuexORM.install(database)],
    state: {
      requireLogin: false, // Display the login screen
      userId: null,
      accountId: null, // Used to override accountId or specify when no router exists
      socketId: null, // Passed in as a header to every HTTP request to prevent socket notifications initiated by this client
      appError: null, // Used for high level error messages
      appLoading: null, // Used for general root-level loading state
      token: null, // Used in clients that don't support cookies
      appDarkMode: get(global.window, 'localStorage.darkMode') === 'on',
      addonsOriginal: null, // Temporary until granular privileges are implemented
      appData: null, // Stores IP, env, versions object, etc
      deviceIdentifier
    },
    getters: {
      clientId () {
        return process?.env?.VUE_APP_CLIENT_ID || global?.VUE_APP_CLIENT_ID
      },
      // Depending what this is used in, accountId can either be set manually in the state
      // or be resolved from the router
      accountId (state) {
        const routeAccountId = state.route?.params?.accountId
        const accountId = (
          state.accountId ||
          routeAccountId ||
          null
        )
        if (accountId) {
          return accountId * 1
        }
        return null
      },
      account (state, getters) {
        return getters.accountId ? Models.Account.find(getters.accountId) : null
      },
      accountFriendlyName (state, getters) {
        return getAccountFriendlyName(getters.account)
      },
      isMultiAccount (state, getters) {
        return getters.account?.parent || getters.account?.children?.length > 0
      },
      user (state) {
        return state.userId ? Models.User.find(state.userId) : null
      },
      requireLogin (state) {
        return state.requireLogin
      },
      userFriendlyName (state, getters) {
        // This logic is very commonly used and should probably be abstracted
        return getUserFriendlyName(getters.user)
      },
      accountUser (state, getters) {
        return state.userId && (getters.accountId || state.accountId)
          ? Models.AccountUser.query()
            .where(u =>
              u._uid + '' === state.userId + '' &&
              (
                u._aid + '' === state.accountId + '' ||
                u._aid + '' === getters.accountId + ''
              )
            ).first()
          : null
      },
      timezone (state, getters) { // Eventually allow User to override this
        return getters?.account?.settings?.timezone || 'UTC'
      },
      device (state, getters) {
        if (state.deviceIdentifier) {
          return Models.Device.query()
            .where(record => record.device_identifier === state.deviceIdentifier)
            .first()
        }
        return null
      },
      addons (state) {
        return Models.Addon.all()
      },
      environment (state, getters) {
        return {
          account: getters.account,
          user: getters.user,
          accountUser: getters.accountUser,
          timezone: getters.timezone,
          addons: getters.addons,
          schemas: getters.schemas
        }
      },
      payload (state, getters) {
        return {
          account: getters.account,
          general: getters.account?.settings?.general || {}
        }
      },
      schemas (state, getters) {
        // Custom for the current account
        const customSchemas = oldFieldsToSchemas(
          Models.Field
            .query()
            .where(f => !!f._id)
            .get()
        )

        const schemas = getSchemas({
          account: getters.account,
          addons: getters.addons,
          customSchemas
        })

        // Add "Groups" as options for contact.groups schemas
        if (schemas['contact.groups']) {
          schemas['contact.groups'] = {
            ...schemas['contact.groups'],
            options: Models.Group.getAsFieldOptions()
          }
        }

        // Add Account contact tags as options for contact.tags schema
        if (schemas['contact.tags']) {
          schemas['contact.tags'] = {
            ...schemas['contact.tags'],
            options: sortCaseInsensitive(
              Models.Tag.query()
                .where(tagRecord => tagRecord.type === 'CONTACT')
                .get()
                .map(t => ({
                  label: t.alias || t.name,
                  value: t.name,
                  count: t.count
                })),
              'label'
            )
          }
        }

        return schemas
      },
      // Schemas for every event type specific to account settings/addons
      schemasByEvent (state, getters) {
        return getEventSchemas({
          account: getters.account,
          schemas: getters.schemas,
          addons: Models.Addon.all()
        })
      },
      // An object of all account's styles with default fallback values
      styles (state, getters) {
        return createStylesObject(getters.account)
      }
    },
    mutations: {
      setRequireLogin (state, value) {
        state.requireLogin = value
      },
      setUserId (state, userId) {
        state.userId = userId
      },
      setAccountId (state, accountId) {
        // This should only be used when there is no router present.  Router should
        // generally specify the account id/
        if (state.route) {
          throw new Error('Do not set accountId when a router is present.')
        }

        if (callbacks && typeof callbacks.afterSelectAccount === 'function') {
          callbacks.afterSelectAccount(accountId)
        }
        state.accountId = accountId
      },
      setSocketId (state, socketId) {
        state.socketId = socketId
      },
      setToken (state, token) {
        state.token = token
      },
      setAppLoading (state, loading) {
        state.appLoading = loading
      },
      setAppError (state, error) {
        state.appError = error
      },
      resetState (state) {
        state.token = null
        state.userId = null
        state.accountId = null
        state.requireLogin = true
      },
      toggleDarkMode (state) {
        state.appDarkMode = !state.appDarkMode
        if (get(global.window, 'localStorage')) {
          window.localStorage.setItem('darkMode', state.appDarkMode ? 'on' : 'off')
        }
        if (get(document, 'getElementsByTagName')) {
          document.getElementsByTagName('body')[0].classList[state.appDarkMode ? 'add' : 'remove']('dark-theme')
        }
      },
      // Temporary until granular privileges are implemented
      setAddonsOriginal (state, value) {
        state.addonsOriginal = value
      },
      setAppData (state, value) {
        state.appData = value
      }
    },
    actions: {
      setAppError ({ commit }, error) {
        clearTimeout(appErrorTimeout)
        commit('setAppError', error)
        appErrorTimeout = setTimeout(() => {
          commit('setAppError', null)
        }, 6000)
      },
      async login ({ commit, dispatch }, requestConfig) {
        commit('setAppError', null)
        if (!requestConfig?.hideLoading) {
          commit('setAppLoading', true)
        }
        try {
          const response = await Vue.$http({
            url: '/v2/auth/token',
            method: 'POST',
            ...requestConfig,
            headers: {
              ...(requestConfig?.headers || {}),
              'x-account-id': ''
            }
          })

          if (get(response, 'data.access_token')) {
            // Set the token to the store
            commit('setToken', response.data.access_token)
          }

          if (callbacks && typeof callbacks.afterLogin === 'function') {
            await callbacks.afterLogin(response)
          }
          if (!requestConfig?.hideLoading) {
            commit('setAppLoading', false)
          }
        } catch (e) {
          if (!requestConfig?.hideLoading) {
            commit('setAppLoading', false)
          }
          if (!requestConfig?.hideErrors) {
            dispatch('setAppError', getErrorMessage(e))
          }
          throw e
        }
      },
      async rootBootstrap ({ commit, dispatch }, requestConfig = {}) {
        commit('setAppError', null)
        if (!requestConfig?.hideLoading) {
          commit('setAppLoading', true)
        }
        if (stateless) {
          return
        }
        await dispatch('empty')
        try {
          const response = await Vue.$http({
            method: 'GET',
            url: '/v2/session',
            ...requestConfig
          })

          if (response.data && response.data.user) {
            Models.User.insert({ data: response.data.user })
            // This triggers the socket to try to authenticate, do this every time
            commit('setUserId', response.data.user._uid)
            commit('setRequireLogin', false)
          } else {
            commit('setRequireLogin', true)
          }
          if (response.data.app) {
            commit('setAppData', response.data.app)
          }
          if (response.data.account_user) {
            Models.AccountUser.insert({ data: response.data.account_user })
          }
          if (response.data.child_accounts) {
            Models.Account.insert({ data: response.data.child_accounts })
          }
          if (response.data.accounts) {
            Models.Account.insert({ data: response.data.accounts })
          }
          if (response.data.device) {
            Models.Device.insert({ data: response.data.device })
          }
          if (response.data.account) {
            Models.Account.insert({ data: response.data.account })
          }
          if (response.data.addons) {
            Models.Addon.insert({ data: response.data.addons })
          }
          if (response.data.addons_original) { // Temporary until granular privileges are implemented
            commit('setAddonsOriginal', response.data.addons_original)
          }
          // Pull account data (would be nice to have these all come from a single endpoint)
          if (response.data.account) {
            await Promise.all([
              Models.Field.fetchMany({
                params: { only_custom: true }
              }),
              Models.Filter.fetchMany({
                params: { limit: 1000 }
              }),
              Models.Group.fetchMany(),
              Models.Tag.fetchMany(),
              ...(
                Object.entries(bootstrapModels || {})
                  .filter(([entity]) => {
                    const model = Models[entity]
                    if (!model) {
                      console.warn(`Invalid Model: ${entity}`)
                    }
                    return model
                  })
                  .map(([entity, config]) => {
                    return Models[entity].fetchMany(config)
                  })
              )
            ])
          }
          if (!requestConfig?.hideLoading) {
            commit('setAppLoading', false)
          }

          if (callbacks && typeof callbacks.afterRootBootstrap === 'function') {
            await callbacks.afterRootBootstrap(response)
          }

          return response
        } catch (e) {
          if (!requestConfig?.hideLoading) {
            commit('setAppLoading', false)
          }
          if (e.response && e.response.status && e.response.status + '' !== '401') {
            // Do nothing if this doesn't work out
            dispatch('setAppError', getErrorMessage(e))
            commit('setRequireLogin', true)
          }
          if (e.response?.status !== '403') {
            dispatch('setAppError', getErrorMessage(e))
            commit('setRequireLogin', true)
          }
          // If unauthorized then let the route handlers take it from here
          throw e
        }
      },
      async empty ({ dispatch }) {
        Models.emptyAccountData()
        if (callbacks && typeof callbacks.afterEmpty === 'function') {
          await callbacks.afterEmpty()
        }
      },
      async logout ({ commit, dispatch }) {
        commit('setAppError', null)
        commit('setAppLoading', true)
        try {
          await Vue.$http({
            url: '/v2/auth',
            method: 'DELETE'
          })
        } catch (e) {
          if (e.response && e.response.status && e.response.status + '' !== '401') {
            // Do nothing if this doesn't work out
            dispatch('setAppError', getErrorMessage(e))
          }
          console.warn('Log out failed at API but we need to purge local state anyway')
          // throw e
        }
        commit('resetState')
        // Clear out local DB
        dispatch('entities/deleteAll')
        commit('setAppLoading', false)
        if (callbacks && typeof callbacks.afterLogout === 'function') {
          await callbacks.afterLogout()
        }
      },
      async handleRecordUpdate (context, options) {
        const { entity, action, relationships } = options
        let { record } = options
        const Model = Models.modelForEntity(entity)
        if (!Model) {
          console.log('no model for entity', entity)
          return
        }
        // console.log('handle record update', {
        //   entity,
        //   action,
        //   record,
        //   relationships
        // })
        if (callbacks?.handleRecordUpdate) {
          await callbacks.handleRecordUpdate(context, options)
        }

        const ModelsToUpdate = [[Model, entity]]
        if (Model.extendedBy?.length) {
          Model.extendedBy.forEach(extendedByEntity => {
            ModelsToUpdate.push([Models.modelForEntity(extendedByEntity), extendedByEntity])
          })
        }

        ModelsToUpdate.forEach(([Model, entity]) => {
          if (['delete'].includes(action)) {
            // Always delete
            Model.delete(record._id || record.id)
          } else if (['update'].includes(action)) {
            // ^ Some `cg-model` events  come in as 'patched' instead of 'updated', because legacy reasons
            // Always update the record if it already exists in the store
            if (Model.socketInsertOnUpdated === 'always') {
              Models.addRecordsToStore({ [Model.entity]: [record] }, { force: true })
              Models.addRecordsToStore(relationships, { force: true })
            } else {
              // Because blocks are subprops of the "special" model Blast, it's a manual process to update those.
              // In the future it would probably be better to move results from /v2/blasts into separate Sequence and Block
              // records and eliminate the Blast model type.
              let existingRecord
              const blastSubrecordTypes = {
                SmsOut: 'message',
                EmailOut: 'message',
                SelectContacts: 'filter',
                Time: 'trigger',
                ActivateOffer: 'activation'
              }
              if (
                entity === 'blocks' &&
                record.sequence_id &&
                Object.keys(blastSubrecordTypes).includes(record.class)
              ) {
                const BlastModel = Models.modelForEntity('blasts')
                const blastRecord = BlastModel.find(record.sequence_id)
                if (blastRecord) {
                  existingRecord = {
                    ...blastRecord,
                    [blastSubrecordTypes[record.class]]: record,
                    updated_at: record.updated_at // Spoof the update time so it updates
                  }
                  record = existingRecord
                  Model = BlastModel
                }
              } else {
                // If it's existing, always update it
                existingRecord = Model.find(record._id || record.id)
              }

              Models.addRecordsToStore({ [Model.entity]: [record] }, { force: !!existingRecord })
              // Sometimes the record is newly added at this point if there's a component specifically listening,
              // so look for it again
              existingRecord = Model.find(record._id || record.id)
              Models.addRecordsToStore(relationships, { force: !!existingRecord })
            }
            // Add it to any paginated components that need it.
            // Model.insertConditionally({ record, relationships })
          } else if (action === 'insert') {
            // Only insert records if they don't already exist.  If they do already exist then they are going to be newer or the same as the inserted record.
            const existingRecord = Model.find(record._id || record.id)
            if (!existingRecord) {
              if (Model.socketInsertOnCreated === 'always') {
                Models.addRecordsToStore({ [Model.entity]: [record] }, { force: true })
                Models.addRecordsToStore(relationships, { force: true })
              } else if (Model.socketInsertOnCreated === 'conditional') {
                // Model.insertConditionally({ record, relationships })
                Models.addRecordsToStore({ [Model.entity]: [record] })
                // If it conditionally adds then force-add relationships based on
                // if it was added
                Models.addRecordsToStore(relationships, { force: !!existingRecord })
              }
            }
          }
        })
      },
      // This should be dispatched anytime the account changes
      afterSelectAccount (context, accountId) {
        if (callbacks && typeof callbacks.afterSelectAccount === 'function') {
          callbacks.afterSelectAccount(accountId)
        }
      }
    }
  }, vuexConfig))

  axiosInstance.attachStore(store)

  return store
}
