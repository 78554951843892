// Actions
import AbTest from './action-ab-test.svg'
import ActivateOffer from './action-activate-offer.svg'
import ConversationStart from './action-conversation-start.svg'
import DeactivateOffer from './action-deactivate-offer.svg'
import DeleteContact from './action-delete-contact.svg'
import DoubleOptIn from './action-double-opt-in.svg'
import Eject from './action-eject.svg'
import EmailOut from './action-email-out.svg'
import Filter from './action-filter.svg'
import FilterByEvents from './action-filter-by-events.svg'
import Http from './action-http.svg'
import MessageReply from './action-message-reply.svg'
import Pause from './action-pause.svg'
import ReportCreate from './action-report-create.svg'
import SelectContacts from './action-select-contacts.svg'
import SetFields from './action-set-fields.svg'
import ShopifyDiscount from './action-shopify-discount.svg'
import SlackMessage from './action-slack-message.svg'
import SmsOut from './action-sms-out.svg'
import Throttle from './action-throttle.svg'
// Triggers
import CalendarItem from './trigger-calendar-item.svg'
import ChatIn from './trigger-chat-in.svg'
import CheckinFinished from './trigger-checkin-finished.svg'
import ContactCreated from './trigger-contact-created.svg'
import ContactRfm from './trigger-contact-rfm.svg'
import ContactUpdated from './trigger-contact-updated.svg'
import ConversationFinished from './trigger-conversation-finished.svg'
import Custom from './trigger-custom.svg'
import DocumentFinished from './trigger-document-finished.svg'
import OfferRedeemed from './trigger-offer-redeemed.svg'
import OrderAbandoned from './trigger-order-abandoned.svg'
import OrderDelivered from './trigger-order-delivered.svg'
import OrderFinished from './trigger-order-finished.svg'
import OrderOutForDelivery from './trigger-order-out-for-delivery.svg'
import OrderShipped from './trigger-order-shipped.svg'
import SmartlinkHit from './trigger-smartlink-hit.svg'
import SmsIn from './trigger-sms-in.svg'
import Time from './trigger-time.svg'
import WebformFinished from './trigger-webform-finished.svg'
import EmptyIcon from './empty.svg'

export default {
  // Actions
  AbTest,
  ActivateOffer,
  ConversationStart,
  DeactivateOffer,
  DeleteContact,
  DoubleOptIn,
  Eject,
  EmailOut,
  Filter,
  FilterByEvents,
  Http,
  MessageReply,
  Pause,
  ReportCreate,
  SelectByEvents: FilterByEvents,
  SelectContacts,
  SetFields,
  ShopifyCreateOrder: ShopifyDiscount,
  ShopifyDiscount,
  SlackMessage,
  SmsOut,
  Throttle,
  // Triggers
  CalendarItem,
  ChatIn,
  CheckinFinished,
  ContactCreated,
  ContactRfm,
  ContactUpdated,
  ConversationFinished,
  Custom,
  DocumentFinished,
  OfferRedeemed,
  OrderAbandoned,
  OrderDelivered,
  OrderFinished,
  OrderOutForDelivery,
  OrderShipped,
  SmartlinkHit,
  SmsIn,
  Time,
  WebformFinished,
  //
  EmptyIcon
}
