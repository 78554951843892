// This mixin passes through vuetify props

export default {
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowCustomPath: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    passProps () {
      return {
        dense: this.dense,
        filled: this.filled,
        outlined: this.outlined,
        disabled: this.disabled,
        allowCustomPath: this.allowCustomPath
      }
    }
  }
}
