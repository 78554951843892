import Vue from 'vue'
import Vuex from 'vuex'
import { merge, cloneDeep, difference } from 'lodash'
import getNodeByPath from './lib/getNodeByPath'
import getPathForNode from './lib/getPathForNode'
import updateNodeByPath from './lib/updateNodeByPath'
import destroyNodeByReference from './lib/destroyNodeByReference'
import blockTypes from '../blocks'
import properties from '../blocks/properties'

const theme = {
  light: {
    colors: {
      primary: '#B9359B',
      secondary: '#912791',
      accent: '#1BBC2D',
      error: '#F44336',
      warning: '#F57C00',
      info: '#1976D2',
      success: '#08B910',
      background: '#ffffff'
    }
  },
  dark: {
    colors: {
      background: '#000000'
    }
  }
}

Vue.use(Vuex)

const defaultState = {
  value: null, // emailObject
  history: [],
  historyIndex: 0,
  darkMode: false,
  disable: false,
  mobile: false,
  menuTab: 'content',
  highlight: null, // ROWS or ELEMENTS
  selectedNodePath: null,
  selectedNodeVm: null,
  rootInstance: null,
  editorOptions: {},
  aiPrompt: null
}

// Timeout for storing history values
const createStore = () => {
  let historyTimeout

  const store = new Vuex.Store({
    state: {
      ...defaultState
    },
    getters: {
      environment (state) {
        return state.editorOptions.environment || {}
      },
      payload (state) {
        return state.editorOptions.payload || {}
      },
      account (state) {
        return state.editorOptions?.environment?.account || {}
      },
      schemas (state) {
        return state.editorOptions?.environment?.schemas || {}
      },
      snippets (state) {
        if (!state.editorOptions?.snippetModel) {
          return
        }
        const snippetIds = (state.value?.children || []).filter(node => node.type === 'snippet' && node.snippetId).map(node => node.snippetId)
        const snippets = []
        const snippetIdsRequested = []
        while (snippetIds.length) {
          const id = snippetIds.pop()
          snippetIdsRequested.push(id)
          const snippet = state.editorOptions.snippetModel.find(id)
          if (snippet) {
            snippets.push(snippet)
            const childSnippetIds = difference((snippet?.content?.children || []).filter(node => node.type === 'snippet' && node.snippetId).map(node => node.snippetId), [])
            if (childSnippetIds.length) {
              childSnippetIds.forEach(_id => snippetIds.push(_id))
            }
          }
        }
        return snippets
      },
      theme (state) {
        const clonedTheme = cloneDeep(theme)
        if (state.darkMode) {
          return merge(
            clonedTheme.light,
            clonedTheme.dark
          )
        }
        return clonedTheme.light
      },
      fatalException (state, getters) { // If this happens, the input value needs to be manually adjusted since the editor cannot help
        return false
      },
      disabled (state, getters) {
        return state.disable || !!getters.fatalException
      },
      selectedNode (state) {
        if (state.selectedNodePath) {
          return getNodeByPath(state.value, state.selectedNodePath)
        }
        return null
      },
      selectedNodeBlockType (state, getters) {
        if (getters.selectedNode) {
          return {
            ...blockTypes.find(bt => bt.id === getters.selectedNode.type),
            properties: properties[getters.selectedNode.type]
          }
        }
        return null
      },
      rootProps (state) {
        return state.value?.props || {}
      }
    },
    mutations: {
      change (state, event) {
        if (event?.[0] === 'ai_prompt') {
          state.aiPrompt = event?.[1]
        }
        if (state.rootInstance?.$parent) {
          state.rootInstance.$parent.$emit('change', event)
        }
      },
      cleanUp (state) {
        Object.keys(defaultState).forEach(key => {
          state[key] = defaultState[key]
        })
      },
      setValue (state, v) {
        v.props = v.props || {}

        state.value = v
        clearTimeout(historyTimeout)
        historyTimeout = setTimeout(() => {
          state.history = [
            v,
            ...state.history.slice(state.historyIndex, state.historyIndex + 200)
          ]
        }, 500)
        state.historyIndex = 0
        if (state.rootInstance) {
          state.rootInstance.$emit('input', state.value)
        }
        // If there are no rows in the message and we're not looking at the body settings, auto change to layout
        if (!state.value?.children?.length && state.menuTab !== 'body') {
          state.menuTab = 'layout'
        }
      },
      setHistoryIndex (state, v) {
        state.historyIndex = v
        state.value = state.history[state.historyIndex]
        if (state.rootInstance) {
          state.rootInstance.$emit('input', state.value)
        }
      },
      changeHistoryIndex (state, v) {
        if (!state.history[state.historyIndex - v]) {
          return
        }
        state.historyIndex -= v
        state.value = state.history[state.historyIndex]
        if (state.rootInstance) {
          state.rootInstance.$emit('input', state.value)
        }
      },
      setMobile (state, v) {
        state.mobile = v
      },
      setMenuTab (state, v) {
        state.menuTab = v
      },
      setHighlight (state, v) {
        state.highlight = v
      },
      setSelectedNodePath (state, v) {
        state.selectedNodePath = v || v === 0 ? (v + '') : null
        if (state.selectedNodePath === null) {
          state.selectedNodeVm = null
        }
      },
      setSelectedNodeVm (state, v) {
        state.selectedNodeVm = v || null
      },
      setRootInstance (state, v) {
        state.rootInstance = v
      },
      setEditorOptions (state, v) {
        state.editorOptions = v
      }
    },
    actions: {
      selectNode ({ state, commit }, node) {
        const path = getPathForNode(state.value, node)
        if (path) {
          commit('setSelectedNodePath', path)
        }
      },
      updateSelectedNode ({ state, commit }, v) {
        commit('setValue', updateNodeByPath(state.value, state.selectedNodePath, v))
      },
      destroyNode ({ state, commit }, node) {
        commit('setValue', destroyNodeByReference(state.value, node))
      },
      showContentMenu ({ commit }) {
        commit('setSelectedNodePath', null)
        commit('setMenuTab', 'content')
      },
      async selectFile ({ state }, currentFile) {
        if (typeof state.editorOptions?.selectFile === 'function') {
          const file = await state.editorOptions.selectFile({ current: currentFile })
          return file
        }
        console.warn('selectFile method not defined in editor instance')
        return null
      }
    }
  })

  window.$getEbStore = () => store
  return store
}

export default createStore
