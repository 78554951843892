import Account from './Account'
import AccountUser from './AccountUser'
import Addon from './Addon'
import AddonType from './AddonType'
import AutoReply from './AutoReply'
import Blast from './Blast'
import Block from './Block'
import BlockTask from './BlockTask'
import CalendarItem from './CalendarItem'
import CardObject from './CardObject'
import Contact from './Contact'
import Conversation from './Conversation'
import Device from './Device'
import Document from './Document'
import EmailAlias from './EmailAlias'
import Event from './Event'
import Field from './Field'
import Fieldset from './Fieldset'
import File from './File'
import Filter from './Filter'
import Flow from './Flow'
import FlowPage from './FlowPage'
import Gateway from './Gateway'
import Group from './Group'
import Import from './Import'
import Indicator from './Indicator'
import Invoice from './Invoice'
import Keyword from './Keyword'
import Offer from './Offer'
import Path from './Path'
import Payment from './Payment'
import PaymentSource from './PaymentSource'
import Product from './Product'
import Report from './Report'
import Sequence from './Sequence'
import ScheduledMessage from './ScheduledMessage'
import Smartlink from './Smartlink'
import Snippet from './Snippet'
import Tag from './Tag'
import Template from './Template'
import Token from './Token'
import User from './User'
import Waiver from './Waiver'
import Webform from './Webform'
import Widget from './Widget'
import initModels from './initModels.js'

const models = {
  Account,
  AccountUser,
  Addon,
  AddonType,
  AutoReply,
  Blast,
  Block,
  BlockTask,
  CalendarItem,
  CardObject,
  Contact,
  Conversation,
  Device,
  Document,
  EmailAlias,
  Event,
  Field,
  Fieldset,
  File,
  Filter,
  Flow,
  FlowPage,
  Gateway,
  Group,
  Import,
  Indicator,
  Invoice,
  Keyword,
  Offer,
  Path,
  Payment,
  PaymentSource,
  Product,
  Report,
  Sequence,
  ScheduledMessage,
  Smartlink,
  Snippet,
  Tag,
  Template,
  Token,
  User,
  Waiver,
  Webform,
  Widget
}

const {
  registerModels,
  addRecordsToStore,
  bulkAddRecordsToStore,
  emptyAccountData,
  modelForEntity,
  countAllRecords
} = initModels(models)

if (window) {
  window.$getModels = () => {
    return {
      ...models,
      emptyAccountData,
      modelForEntity,
      countAllRecords
    }
  }
}

export default models

export {
  registerModels,
  addRecordsToStore,
  bulkAddRecordsToStore,
  emptyAccountData,
  modelForEntity,
  countAllRecords,
  Account,
  AccountUser,
  Addon,
  AddonType,
  AutoReply,
  Blast,
  Block,
  BlockTask,
  CalendarItem,
  CardObject,
  Contact,
  Conversation,
  Device,
  Document,
  EmailAlias,
  Event,
  Field,
  Fieldset,
  File,
  Filter,
  Flow,
  FlowPage,
  Gateway,
  Group,
  Import,
  Indicator,
  Invoice,
  Keyword,
  Offer,
  Path,
  Payment,
  PaymentSource,
  Product,
  Report,
  Sequence,
  ScheduledMessage,
  Smartlink,
  Snippet,
  Tag,
  Template,
  Token,
  User,
  Waiver,
  Webform,
  Widget
}
