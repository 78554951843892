<template>
  <div
    v-if="extendedSendTime"
  >
    <v-alert
      color="warning"
      dark
      icon="fa-solid fa-triangle-exclamation"
    >This is a large blast and could take up to <strong>{{ extendedSendTime }} hours</strong> to completely send depending on our current sending volume.</v-alert>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    extendedSendTime () {
      let hoursToSend
      if (this.count > 200000) {
        hoursToSend = 6
      } else if (this.count > 100000) {
        hoursToSend = 4
      } else if (this.count >= 50000) {
        hoursToSend = 2
      }
      return hoursToSend
    }
  }
}
</script>
