var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.schema.input_type === 'button-group' || _vm.fieldProps.input_type === 'button-group')?_c('field-custom-wrapper',_vm._b({staticClass:"field-button-group",attrs:{"errorMessages":_vm.ruleError}},'field-custom-wrapper',{
    ..._vm.vuetifyComponentProps,
    prefix: undefined,
    suffix: undefined,
    label: null,
    rules: _vm.ruleError === undefined ? _vm.rules : []
  },false),[_c('div',{staticClass:"my-3 justify-center text-center"},[(_vm.vuetifyComponentProps.label)?_c('p',{staticClass:"mt-0 mb-1"},[_vm._v(_vm._s(_vm.vuetifyComponentProps.label))]):_vm._e(),_c('v-btn-toggle',_vm._b({attrs:{"value":_vm.value,"borderless":_vm.containsUnicode},on:{"change":_vm.handleButtonInput}},'v-btn-toggle',_vm.vuetifyComponentProps,false),_vm._l((_vm.items),function(item,$index){return _c('v-btn',{key:$index,style:(_vm.containsUnicode ? 'font-size: 2em' : ''),attrs:{"value":item.value,"large":""}},[_vm._v(_vm._s(item.text))])}),1)],1)]):_c('v-autocomplete',_vm._b({ref:"focusTarget",attrs:{"items":_vm.items,"value":_vm.value,"search-input":_vm.searchSync,"aria-autocomplete":"none","auto-select-first":""},on:{"update:searchInput":function($event){_vm.searchSync=$event},"update:search-input":function($event){_vm.searchSync=$event},"input":_vm.handleInput}},'v-autocomplete',_vm.vuetifyComponentProps,false))
}
var staticRenderFns = []

export { render, staticRenderFns }