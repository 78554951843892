import booknow from './booknow'
import courtreserve from './courtreserve'
import offers from './offers'
import playbypoint from './playbypoint'
import revel from './revel'
import roller from './roller'
import shopify from './shopify'
import smartwaiver from './smartwaiver'
import square from './square'
import wordpress from './wordpress'
import { fromPairs } from 'lodash'

const addonTypes = {
  booknow,
  courtreserve,
  offers,
  playbypoint,
  revel,
  roller,
  shopify,
  smartwaiver,
  square,
  wordpress
}

/**
 * Accepts an array of addon records and returns an object of all schemas
 * that are added by that addon.
 * @param {[Addon]} options.addons - array of addon records that an account has
 * @param {[String]} options.entities - List of entities to filter by
 */
const getSchemasForAddons = (options = {}) => {
  let allSchemas = {};
  (options.addons || []).forEach(addon => {
    if (addon.type && addonTypes[addon.type.toLowerCase()]) {
      const addonType = addonTypes[addon.type.toLowerCase()]
      let addonTypeSchemas
      if (addonType.schemas && typeof addonType.schemas === 'object') {
        addonTypeSchemas = addonType.schemas
      } else if (addonType.getSchemas && typeof addonType.getSchemas === 'function') {
        addonTypeSchemas = addonType.getSchemas(options)
      }
      addonTypeSchemas = fromPairs(
        Object.entries(addonTypeSchemas).map(([path, schema]) => {
          return [
            path,
            {
              ...schema,
              path: schema.path || path
            }
          ]
        })
      )
      allSchemas = {
        ...allSchemas,
        ...(addonTypeSchemas || {})
      }
    }
  })
  return allSchemas
}

export {
  getSchemasForAddons
}
