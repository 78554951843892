import Model from './Model'

class Product extends Model {
  static entity = 'products'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      description: this.attr(undefined),
      price: this.attr(undefined),
      type: this.attr(undefined),
      tags: this.attr(undefined),
      status: this.attr(undefined),
      subscription: this.attr(undefined),
      parent_id: this.attr(undefined),
      external_id: this.attr(undefined),
      addon_id: this.attr(undefined),
      addon_type: this.attr(undefined)
    }
  }
}

export default Product
