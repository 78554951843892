// Returns an object of all schemas added based on the BookNow addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._external_id': {
      type: 'text',
      name: 'BookNow Customer ID'
    },
    'contact._booknow_created_at': {
      type: 'timestamp',
      immutable: true,
      name: 'BookNow Created At'
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
