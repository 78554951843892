export default ({ account = {} } = {}) => {
  return {
    title: { name: 'Calendar Item Title', type: 'text' },
    tags: {
      name: 'Calendar Item Tags',
      type: 'text',
      multiple: true
    },
    start_at: { name: 'Calendar Item Start At', type: 'timestamp' },
    end_at: { name: 'Calendar Item End At', type: 'timestamp' }
  }
}
