import Trigger from '../Trigger'
import { applyOffset } from 'shared/util/time/offset.js'
import moment from 'moment-timezone'

class CalendarItem extends Trigger {
  static get title () {
    return 'Calendar Event'
  }

  static get class () {
    return 'CalendarItem'
  }

  static get restrict () {
    return { 'addons.type': 'CALENDAR' }
  }

  static getDescription ({ block = {} } = {}) {
    if (block && block.props && block.props.interval) {
      const interval = block.props.interval
      return [
        [interval.years, 'Y'],
        [interval.months, 'M'],
        [interval.weeks, 'W'],
        [interval.days, 'D'],
        [interval.hours, 'h'],
        [interval.minutes, 'm']
      ].filter(([amt]) => !!amt && amt > 0)
        .map(([amt, suffix]) => `${amt}${suffix}`)
        .join(',') + ' ' + (block.props.before ? 'before' : 'after') + ' ' + (block.props.target_time === 'end_at' ? 'end' : 'start')
    }
    return ''
  }

  static getErrors (environment = {}) {
    // eslint-disable-next-line no-unused-vars
    const { sequence, block, blocks, paths } = environment
    const errors = []

    const now = moment()
    const nowWithOffset = applyOffset(block?.props?.interval, now)
    if (nowWithOffset.unix() - now.unix() > 28 * 24 * 3600) {
      errors.push({
        message: 'Timeframe cannot be more than 28 days.'
      })
    }
    // If the interval is greater than 4 weeks, throw an error
    return super.getErrors(environment).concat(errors)
  }
}

export default CalendarItem
